import { FORM_TEMPLATES } from './Constants';
import SelectBox from '@/UIComponents/SelectBox';
import AdvertisingAllowanceComponent from './InputComponents/AdvertisingAllowance';
import { useId, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonAction } from '@/UIComponents/Button';
import InputComponent from './InputComponents/InputComponent/InputComponent';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendLeadUtil } from 'hooks/useLead';
import Loader from './FormMessages/Loader';
import useContactUs from 'providers/useContactUs';


const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_FRONTENT_SITE_KEY;
const SEND_LABEL = 'שליחה';
const InputPhone = (register, errors, size = 'big') => {
  const phonePattern = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;

  return (
    <InputComponent
      register={register}
      size={size}
      id="MobilePhone"
      type="tel"
      validations={{ pattern: phonePattern, required: true }}
      errors={errors}
      placeholder="טלפון"
    />
  );
};

const inputName = (register, errors, size = 'big', placeholder = 'שם מלא', id = 'LastName') => {
  return (
    <InputComponent
      register={register}
      size={size}
      id={id}
      validations={{ required: true }}
      errors={errors}
      placeholder={placeholder}
    />
  );
};

const inputEmail = (register, errors, size = 'big') => {
  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    <InputComponent
      register={register}
      size={size}
      id="Email"
      type="email"
      validations={{ pattern: emailPattern, required: true }}
      errors={errors}
      placeholder='דוא"ל'
    />
  );
};
const basicFormFields = (register, errors) => {
  return (
    <>
      {inputName(register, errors)}
      {InputPhone(register, errors)}
      {inputEmail(register, errors)}
    </>
  );
};

const handleSubmitForm =
  ({recaptchaRef, setLeadResponse, utmValues, showRoom, utmCreativeFormat, ServicesInterest, model, leadType}) =>
    async (formData) => {
      setLeadResponse({ isSubmitting: true });
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      if (recaptchaToken) {
        const {
          LastName,
          Email,
          MobilePhone,
          LicensePlate,
          HasOptedIn,
          CustomerComment = '',
          CustomerId,
          ProductOfInterest,
          Showrooms,
          FirstName
        } = formData || {};

        const subject = formData?.ServicesInterest || ServicesInterest?.value;
        const { webCode, serviceType, contactUsType, serviceInterest, subjectName } = subject || {};

        const lead = {
          LastName,
          FirstName,
          Email,
          MobilePhone,
          LicensePlate,
          HasOptedIn: HasOptedIn ? 'X' : '',
          CustomerId,
          gRecaptchaResponse: recaptchaToken,
          ProductOfInterest: model || ProductOfInterest,
          ShowRoom: showRoom || Showrooms
        };

        if(subjectName && !leadType === FORM_TEMPLATES.testDrive) {
          lead.CustomerComment = `${subjectName} ${CustomerComment ? ':' + CustomerComment : ''}`;
        }

        lead.WebCode = webCode;
        lead.ServiceType = serviceType;
        lead.ContactUsType = contactUsType;

        if (utmCreativeFormat) {
          lead.UTMCreativeFormat = utmCreativeFormat;
          if (!utmValues?.UTMMedium) {
            lead.UTMMedium = utmCreativeFormat;
          }
        }

        if (utmValues?.UTMMedium) {
          lead.UTMMedium = utmValues?.UTMMedium;
        }

        if (utmValues?.UTMSource) {
          lead.UTMSource = utmValues?.UTMSource;
        }
        if (utmValues?.UTMTerm) {
          lead.UTMTerm = utmValues?.UTMTerm;
        }
        if (utmValues?.LandingPageURL) {
          lead.LandingPageURL = utmValues?.LandingPageURL;
        }

        if (utmValues?.Referral) {
          lead.Referral = utmValues?.Referral;
        }
        if (utmValues?.UTMCampaign) {
          lead.UTMCampaign = utmValues?.UTMCampaign;
        }

        if (serviceInterest) {
          lead.ServicesInterest = serviceInterest;
        }

        if (showRoom) {
          lead.ShowRoom = showRoom;
        }

        let ResponseObj;

        try {
          const leadResponse = await sendLeadUtil(lead, leadType);
          if (leadResponse && leadResponse.data) {
            if (leadResponse.data.error) {
              const errorMessage =
              leadResponse?.data?.error?.message || leadResponse.data?.error?.error || leadResponse?.data?.error;
              throw Error(errorMessage);
            }

            if (leadResponse.data && !leadResponse.data.OTPId) {
              ResponseObj = { isSentSuccess: true };
              recaptchaRef?.current?.reset();
            }
          }
        } catch (message) {
          recaptchaRef?.current?.reset();
          ResponseObj = { isSentSuccess: false, message };
        }
        ResponseObj.isFormSubmitted = true;
        setLeadResponse(ResponseObj);
      }
    };

export const formTemplatesJSX = (formFields, setLeadResponse, leadResponse, colmobilGrpImage) => {
  const {
    formTemplate,
    utmCreativeFormat,
    subject,
    sendLabel = SEND_LABEL,
    remark,
    models,
    model,
    showRooms,
    showRoom
  } = formFields || {};

  const template = FORM_TEMPLATES[formTemplate];

  const subjects = subject?.map(({ formSubjectsFields, name: subjectName }) => {
    return {
      text: subjectName,
      value: { ...formSubjectsFields, subjectName }
    };
  });
  const utmValues = getUTMValues();
  const formRef = useRef();
  const recaptchaRef = useRef();
  const uniqueId = useId();
  const formId = `contact_form_${uniqueId}`;

  const handleSubmitExtraProps = {recaptchaRef, setLeadResponse, utmValues, showRoom, utmCreativeFormat};

  if (subjects?.length === 1) {
    handleSubmitExtraProps.ServicesInterest = subjects[0];
  }

  if (model) {
    handleSubmitExtraProps.model =model;
  }

  if(formTemplate === FORM_TEMPLATES.testDrive){
    handleSubmitExtraProps.leadType = FORM_TEMPLATES.testDrive;
  }
  
  const onSubmit = handleSubmitForm(handleSubmitExtraProps);
  let fieldsJSX = <></>;

  const [activeInput, setActiveInput] = useState({
    HasOptedIn: false,
    ServicesInterestTradeInTradeIn: false
  });

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const basicFormFieldsJSX = basicFormFields(register, errors, subjects, setValue);
  const {contactUsData: {setContactUsFilled}} = useContactUs() || {};
  if(leadResponse?.isFormSubmitted){
    setContactUsFilled(true);
    setValue('MobilePhone', '');
    setValue('LastName', '');
    setValue('Email', '');
    setValue('ProductOfInterest', '');
    if(activeInput?.HasOptedIn){
      setActiveInput({
        HasOptedIn: false,
        ServicesInterestTradeInTradeIn: false
      });
    }
  }

  switch (template) {
    case FORM_TEMPLATES.basic:
      fieldsJSX = basicFormFieldsJSX;
      break;

    case FORM_TEMPLATES.basicWithComment:
      fieldsJSX = (
        <>
          {basicFormFieldsJSX}
          <InputComponent register={register} id="CustomerComment" placeholder="תוכן הפניה" asType="textarea" />
        </>
      );
      break;

    case FORM_TEMPLATES.basicWithModel: {
      const ProductOfInterestJSX = (
        <SelectBox
          id="ProductOfInterest"
          errors={errors}
          setValue={setValue}
          register={register}
          list={models}
          placeholder="בחר"
          label="דגם"
        />
      );
      fieldsJSX = (
        <>
          {inputName(register, errors)}
          {InputPhone(register, errors)}
          {!model && ProductOfInterestJSX}
          {inputEmail(register, errors)}
        </>
      );

      break;
    }
    case FORM_TEMPLATES.withShowRoom: {
      const ProductOfInterestJSX = (
        <SelectBox
          id="ProductOfInterest"
          errors={errors}
          setValue={setValue}
          register={register}
          list={models}
          placeholder="דגם"
          label="דגם"
        />
      );
      const SHOW_ROOM = 'אולם תצוגה';
      const CHOOSE_SHOW_ROOM = 'בחירת אולם תצוגה';
      fieldsJSX = (
        <>
          {inputName(register, errors)}
          {InputPhone(register, errors)}
          <SelectBox
            id="Showrooms"
            errors={errors}
            setValue={setValue}
            register={register}
            required
            list={showRooms}
            placeholder={CHOOSE_SHOW_ROOM}
            label={SHOW_ROOM}
          />
          {!model && ProductOfInterestJSX}
          {inputEmail(register, errors)}
          <InputComponent id="CustomerComment" register={register} placeholder="תוכן הפניה" asType="textarea" />
        </>
      );

      break;
    }

    case FORM_TEMPLATES.basicWithLicense: {
      const licensePattern = /^\d{7}(?:\d{1})?$/;
      const licenseInput = (
        <InputComponent
          register={register}
          id="LicensePlate"
          validations={{ required: true, pattern: licensePattern }}
          errors={errors}
          placeholder="מספר רישוי"
        />
      );
      fieldsJSX = (
        <>
          {inputName(register, errors)}
          {inputEmail(register, errors)}
          {InputPhone(register, errors)}
          {licenseInput}
          <InputComponent register={register} id="CustomerComment" placeholder="תוכן הפניה" asType="textarea" />
        </>
      );
      break;
    }
    case FORM_TEMPLATES.testDrive: {
      const SHOW_ROOM = 'אולם תצוגה';
      const CHOOSE_SHOW_ROOM = 'בחירת אולם תצוגה';

      fieldsJSX = <>
        {inputName(register, errors, 'big', 'שם פרטי', 'FirstName')}
        {inputName(register, errors, 'big', 'שם משפחה')}
        {InputPhone(register, errors)}
        {inputEmail(register, errors)}
        <SelectBox
          id="Showrooms"
          errors={errors}
          setValue={setValue}
          register={register}
          required
          list={showRooms}
          placeholder={CHOOSE_SHOW_ROOM}
          label={SHOW_ROOM}
        />
        <SelectBox
          id="ProductOfInterest"
          errors={errors}
          setValue={setValue}
          register={register}
          list={models}
          placeholder="בחר"
          label="דגם"
        />
      </>;
      break;
    }
    default:
      fieldsJSX = basicFormFieldsJSX;
      break;
  }

  const isSubmitting = leadResponse?.isSubmitting;

  fieldsJSX = (
    <form
      id={formId}
      className="contact-form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate="novalidate"
      ref={formRef}
    >
      {subjects?.length > 1 && (
        <SelectBox
          size="big"
          id="ServicesInterest"
          errors={errors}
          setValue={setValue}
          register={register}
          required
          list={subjects}
          placeholder="בחר"
          label="נושא פנייה"
        />
      )}
      {fieldsJSX}
      {remark && <span className="legal-remark">{remark}</span>}
      <AdvertisingAllowanceComponent
        isActive={activeInput.HasOptedIn}
        errors={errors}
        inputAttributes={{
          default: '',
          name: 'HasOptedIn',
          labelLink: '/'
        }}
        activeInput={activeInput}
        setActiveInput={setActiveInput}
        register={register}
        key="has-opted-in"
        errorText={''}
        colmobilGrpImage={colmobilGrpImage}
      />
      <div className="button-wrapper">
        <ButtonAction color="light">
          <span className={`button-text${isSubmitting ? ' submitting' : ''}`}>{sendLabel}</span>
          {isSubmitting && <Loader color="white" width={15} height={15} />}
        </ButtonAction>
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} size="invisible" hl={'iw'} />
    </form>
  );
  return fieldsJSX;
};

export const getUTMValues = () => {
  if (
    typeof window !== 'undefined' &&
    typeof document !== 'undefined' &&
    typeof window.sessionStorage !== 'undefined'
  ) {
    const params = {};

    const UTMSource = window.sessionStorage.getItem('utm_source');
    if (UTMSource) {
      params.UTMSource = UTMSource;
    }

    const UTMTerm = window.sessionStorage.getItem('utm_term');
    if (UTMTerm) {
      params.UTMTerm = UTMTerm;
    }

    const Referral = window.sessionStorage.getItem('referrer');
    if (Referral) {
      params.Referral = Referral;
    }

    const LandingPageURL = window.sessionStorage.getItem('location');
    if (LandingPageURL) {
      params.LandingPageURL = LandingPageURL;
    }

    const UTMMedium = window.sessionStorage.getItem('utm_medium');
    if (UTMMedium) {
      params.UTMMedium = UTMMedium;
    }

    const UTMCampaign = window.sessionStorage.getItem('utm_campaign');
    if (UTMCampaign) {
      params.UTMCampaign = UTMCampaign;
    }

    return params;
  }
};
