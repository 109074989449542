import { sendLead } from '@colmobil/colmolib/minify/leadsHyundai';

const LeadDictionary = {
  'LastName': '',
  'Email': '',
  'MobilePhone': '',
  'HasOptedIn': '',
  'ContactUsType': 'Sales',
  'LeadSource': 'Web',
  'BusinessProcess': 'Smart',
  'ShowRoom': '',
  'ProductOfInterest':'',
  'LicensePlate': '',
  'CustomerId':'',
  'CustomerComment': '',
  'UTMCampaign': 'sm_colmobil-websites_0000',
  'UTMSource': 'smart_website',
  'UTMContent':'contact_section',
  'UTMMedium': '',
  'UTMTerm': '',
  'UTMCreativeFormat':'',
  'LandingPageURL': '',
  'Referral': '',
  'ServicesInterest': '',
  'gRecaptchaResponse': '',
  'ContactMe': true,
  'WebCode': '',
  'ServiceType':''
};

const TestDriveLeadDictionary = {
  'LastName': '',
  'FirstName': '',
  'Email': '',
  'MobilePhone': '',
  'HasOptedIn': '',
  'ContactUsType': 'Sales',
  'LeadSource': 'Web',
  'BusinessProcess': 'Smart',
  'ShowRoom': '',
  'ProductOfInterest':'',
  'CustomerComment': 'נסיעת מבחן מהאתר',
  'UTMCampaign': 'sm_websiteTestDrivePilot_0000',
  'UTMSource': 'smart_website',
  'UTMMedium': '',
  'UTMCreativeFormat':'',
  'LandingPageURL': '',
  'Referral': '',
  'gRecaptchaResponse': '',
  'ContactMe': true,
  'WebCode': 'TestDrive',
};

/**
 * Append form hand fields values to the dictionary from the lead sending stage
 * @param {*} lead
 * @param {*} type
 * @returns OrderDictionary
 */
const prepareLead = (lead, leadType = '') => {

  let dictionary = '';
  
  switch (leadType) {
    case 'testDrive':
      dictionary = TestDriveLeadDictionary;
      break;

    default:
      dictionary = LeadDictionary;
      break;
  }

  const res = {};

  for (const index in dictionary) {
    if (index in lead) {
      res[index] = lead[index];
    } else {
      res[index] = dictionary[index];
    }
  }
  return JSON.stringify({ 'Request': res });
};


/**
 * Send this Lead to colmolib plugin
 * @param {*} lead
 * @param {*} type
 * @returns
 */
export const sendLeadUtil = async (lead, type) => {
  const leadData = prepareLead(lead, type);
  const method = { method: 'POST' };
  const res = sendLead(method,leadData);
  return res;
};