'use client';

import propTypes from 'prop-types';
import StyledSuccessMessage from './StyledSuccessMessage';
import { CONSTANTS } from '../../Constants';

const SuccessMessage = ({successFields}) => {
  const {title, description} = successFields || {};
  const SUCCESS_TITLE = title || CONSTANTS.THANK_YOU;
  const MESSAGE_CONTACT = description || CONSTANTS.SUCCESS_MSG;

  return ( 
    <StyledSuccessMessage className='success'>
      <div className="infos-container">
        <h3 className="infos-container-title" dangerouslySetInnerHTML={{__html:SUCCESS_TITLE}}/>
        <h4 className="infos-container-message" dangerouslySetInnerHTML={{__html:MESSAGE_CONTACT}}/>
      </div>
    </StyledSuccessMessage>
  );
};

SuccessMessage.propTypes = {
  successFields: propTypes.object,
};

export const dynamic = 'error';
export default SuccessMessage;
