import { getUTMValues } from '@/UIComponents/ContactForm/utils';
import { getMASKU } from '@colmobil/colmolib/minify/getMASKU';

export const fetchAndSetMaskuPhone = async (setPhone, defaultPhone, site) => {
  const sessionMaskuPhone = sessionStorage.getItem('maskuPhone');

  if (sessionMaskuPhone) {
    setPhone(sessionMaskuPhone);
    return;
  }

  const { UTMCampaign, UTMMedium, UTMSource } = getUTMValues() || {};
  if (UTMCampaign && UTMMedium && UTMSource) {
    const maskuPhone = await getMASKU(UTMCampaign, UTMSource, UTMMedium, site);
    if (maskuPhone) {
      sessionStorage.setItem('maskuPhone', maskuPhone);
    }
    setPhone(maskuPhone || defaultPhone);
  } else {
    setPhone(defaultPhone);
  }
};
