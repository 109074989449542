'use client';
import propTypes from 'prop-types';
import SuccessMessage from './FormMessages/Success/SuccessMessage';
import ErrorMessage from './FormMessages/Error/ErrorMessage';
import StyledContactForm from './StyledContactForm';
import { formTemplatesJSX } from './utils';

const ContactForm = ({ formFields, setLeadResponse, leadResponse, colmobilGrpImage }) => {
  const {successFields = {}} = formFields || {};
  const fields = formTemplatesJSX(formFields, setLeadResponse, leadResponse, colmobilGrpImage);

  const { isFormSubmitted = false, isSentSuccess = false } = leadResponse || {};

  const SendMessage = isSentSuccess ? <SuccessMessage successFields={successFields} /> : <ErrorMessage />;

  return <StyledContactForm className="contact-form">{!isFormSubmitted ? fields : SendMessage}</StyledContactForm>;
};

ContactForm.propTypes = {
  formFields: propTypes.object,
  handleClose: propTypes.func,
  setLeadResponse: propTypes.func,
  leadResponse: propTypes.object,
  colmobilGrpImage: propTypes.object
};
export default ContactForm;
