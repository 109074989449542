import styled from 'styled-components';

const StyledHeader = styled.div`
  height: ${({ theme }) => theme.layoutHeights?.header}px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;

  ${({ positionY }) =>
    positionY > 0
      ? ` 
      background: #ffffff95;
      backdrop-filter: blur(8px);
    `
      : 'background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.25) 60%, rgba(0, 0, 0, 0.08) 82%, rgba(0, 0, 0, 0) 100%);'}

  .menu-button {
    .arrow-down {
      ${({ positionY }) => (positionY > 0 ? 'border-color: #000 !important;' : 'border-color: #fff')}
    }
  }

  .text {
    ${({ positionY }) => (positionY > 0 ? 'color: #000;' : 'color: #fff')}
  }

  .menu-item {
    width: 32px;
  }

  .header-menu {
    cursor: pointer;
    top: 26px;
    color: ${({ theme }) => theme.colors?.white};
    display: inline-flex;
    justify-content: center;
    position: absolute;
    right: 240px;
    align-items: center;
    gap: 10px;

    .header-item {
      a {
        color: ${({ theme }) => theme.colors?.white};
      }
    }
  }

  .menu-wrap {
    position: absolute;
    top: 65px;
    right: ${({ isShown }) => (isShown ? '80px' : '-100%')};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 80vh;
    width: 560px;
    padding: 87px 0;
    background-color: ${({ theme }) => theme.colors?.dark};
    border-radius: 20px;
    transition: all 0.5s ease-in;

    ${({ theme }) => theme.bps?.mobile} {
      width: 100vw;
      height: 100vh;
      top: 0;
      right: ${({ isShown }) => (isShown ? '0' : '-100%')};
      border-radius: 0;
      z-index: -1;
      transition: all 0.2s ease-in;
    }

    .menu-item {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 16px 70px;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.buttons.disabledHover};
      }

      &.with-sub-menu {
        &:after {
          position: absolute;
          content: '';
          left: 70px;
          width: 9px;
          height: 9px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          border-left: 1px solid ${({ theme }) => theme.colors?.white};
          border-bottom: 1px solid ${({ theme }) => theme.colors?.white};
          transform: rotate(45deg);
        }

        &.sub-menu-open {
          background-color: ${({ theme }) => theme.colors?.white};

          ${({ theme }) => theme.bps?.mobile} {
            background-color: ${({ theme }) => theme.colors?.dark};
          }

          .menu-item-label {
            > a,
            span {
              color: ${({ theme }) => theme.colors?.dark};

              ${({ theme }) => theme.bps?.mobile} {
                color: ${({ theme }) => theme.colors?.white};
              }
            }
          }

          &:after {
            border-left: 1px solid ${({ theme }) => theme.colors?.dark};
            border-bottom: 1px solid ${({ theme }) => theme.colors?.dark};

            ${({ theme }) => theme.bps?.mobile} {
              border-left: 1px solid ${({ theme }) => theme.colors?.white};
              border-bottom: 1px solid ${({ theme }) => theme.colors?.white};
            }
          }
        }
      }

      .menu-item-label {
        > a,
        span {
          font-family: ${({ theme }) => theme.fonts?.ploni};
          font-size: 20px;
          line-height: 28px;
          font-weight: bold;
          color: ${({ theme }) => theme.colors?.white};

          ${({ theme }) => theme.bps?.mobile} {
            font-size: 16px;
          }
        }
      }
    }
  }

  .sub-menu-wrap {
    position: absolute;
    top: 65px;
    right: 625px;
    width: ${({ isSubMenuShown }) => (isSubMenuShown ? '560px' : '0')};
    height: 80vh;
    background-color: ${({ theme }) => theme.colors?.white};
    padding: 87px 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: all 0.3s ease-in;
    overflow: hidden;

    ${({ theme }) => theme.bps?.mobile} {
      top: 0;
      left: 0;
      right: unset;
      background-color: ${({ theme }) => theme.colors?.dark};
      width: ${({ isSubMenuShown }) => (isSubMenuShown ? '100vw' : '0')};
    }

    .sub-menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      white-space: nowrap;

      .sub-menu-item {
        width: 100%;
        padding: 16px 70px;

        > a {
          color: ${({ theme }) => theme.colors?.black};
          font-family: ${({ theme }) => theme.fonts?.ploni};
          font-size: 20px;
          line-height: 28px;
          font-weight: bold;

          ${({ theme }) => theme.bps?.mobile} {
            color: ${({ theme }) => theme.colors?.white};
            font-size: 16px;
          }
        }

        &:first-of-type {
          > a {
            font-size: 26px;
            line-height: 31px;
          }
        }
      }
    }
  }
`;

export default StyledHeader;
